import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
// @mui
import { Box } from '@mui/material';
//
import { StyledRootScrollbar, StyledScrollbar } from './styles';
// ----------------------------------------------------------------------
function Scrollbar({ children, sx, ...other }) {
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    if (isMobile) {
        return (_jsx(Box, { sx: { overflowX: 'auto', ...sx }, ...other, children: children }));
    }
    return (_jsx(StyledRootScrollbar, { children: _jsx(StyledScrollbar, { clickOnTrack: false, sx: sx, ...other, children: children }) }));
}
export default memo(Scrollbar);
